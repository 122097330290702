<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <form class="needs-validation" @submit.prevent="formWage">
                <div class="row">
                  <div class="col-md-3 col-sm-5">
                    <div class="mb-3 position-relative">
                      <label for="branchId">สาขา:</label>
                      <multiselect
                        id="branchId"
                        v-model="form.branchId"
                        :options="localDataBranch"
                        :class="{
                          'is-invalid': submitform && $v.form.branchId.$error,
                        }"
                        track-by="nameTh"
                        label="nameTh"
                        :show-labels="false"
                      ></multiselect>

                      <div
                        v-if="submitform && $v.form.branchId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.branchId.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="wageCode">รหัสค่าแรง:</label>
                      <input
                        id="wageCode"
                        v-model="form.wageCode"
                        type="text"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid': submitform && $v.form.wageCode.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.wageCode.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.wageCode.maxLength">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code>
                      <label for="nameTh">ชื่อค่าแรง (ภาษาไทย):</label>
                      <input
                        id="nameTh"
                        v-model="form.nameTh"
                        type="text"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid': submitform && $v.form.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.nameTh.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="nameEn">ชื่อค่าแรง (ภาษาอังกฤษ):</label>
                      <input
                        id="nameEn"
                        v-model="form.nameEn"
                        type="text"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid': submitform && $v.form.nameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.nameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.nameEn.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="maxDcPer">ส่วนลดสูงสุด(%):</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="maxDcPer"
                          v-model="form.maxDcPer"
                          class="form-control text-end"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.maxDcPer.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.maxDcPer.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.form.maxDcPer.maxValue ||
                              !$v.form.maxDcPer.minValue
                            "
                            >{{ message }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="maxDcAmount">ส่วนลดสูงสุด(บาท):</label>
                      <div class="input-group">
                        <input
                          :min="1"
                          id="maxDcAmount"
                          v-model="form.maxDcAmount"
                          class="form-control text-end"
                          placeholder=""
                          :class="{
                            'is-invalid':
                              submitform && $v.form.maxDcAmount.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.maxDcAmount.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.maxDcAmount.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="wageType">ประเภทค่าแรง:</label>
                      <multiselect
                        id="wageType"
                        v-model="form.wageType"
                        :options="optionsWage"
                        :class="{
                          'is-invalid': submitform && $v.form.wageType.$error,
                        }"
                        track-by="name"
                        label="name"
                        :show-labels="false"
                        @select="dis"
                      ></multiselect>

                      <div
                        v-if="submitform && $v.form.wageType.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.wageType.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="price">ค่าแรงต่อชั่วโมง(ครั้ง):</label>
                      <div class="input-group">
                        <input
                          :disabled="isDisable"
                          :precision="2"
                          id="price"
                          v-model="form.price"
                          class="form-control text-end"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.form.price.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.price.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.price.numeric">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-2 col-sm-3">
                    <div class="mb-3 position-relative">
                      <label for="workDuration">ระยะเวลาทำงาน(ชั่วโมง):</label>
                      <div class="input-group">
                        <input
                          id="workDuration"
                          v-model="form.workDuration"
                          :disabled="isDisabled"
                          type="text"
                          class="form-control text-end"
                          placeholder=""
                          :class="{
                            'is-invalid':
                              submitform && $v.form.workDuration.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.form.workDuration.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.workDuration.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md">
                      <b-form-checkbox
                        v-model="form.useInBp"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ใช้กับศูนย์บริการ</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="form.useInGs"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ใช้กับศูนย์ซ่อมสีและตัวถัง</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
                <br />

                <div class="col-md">
                  <button class="btn btn-success float-end" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </b-overlay>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  // numeric
  decimal,
  maxValue,
  minValue,
  maxLength,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";

/**
 * Form validation component
 */
export default {
  mounted() {},
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  page: {
    title: appConfig.wages,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      message: "โปรดตรวจสอบข้อมูล",
      title: "แก้ไขข้อมูลค่าแรง",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ศูนย์บริการ",
          active: true,
        },
        {
          text: appConfig.wages,
          href: "/branch-wages",
        },
        {
          text: "แก้ไขข้อมูลค่าแรง",
          active: true,
        },
      ],
      isDisabled: false,
      isDisable: false,
      localDataBranch: [],
      optionsWage: [
        {
          name: "HR",
          id: "HR",
        },
        {
          name: "PX",
          id: "PX",
        },
        {
          name: "FRE",
          id: "FRE",
        },
      ],
      overlayFlag: false,
      wageId: this.$route.params.wageId,
      options: [],
      form: {
        branchId: "",
        nameTh: "",
        nameEn: "",
        price: "",
        wageType: "",
        wageCode: "",
        maxDcPer: "",
        maxDcAmount: "",
        useInBp: "",
        useInGs: "",
        workDuration: "",
      },

      submitform: false,
    };
  },

  validations: {
    form: {
      nameTh: {
        required,
      },
      nameEn: {},
      price: {
        decimal,
      },
      wageType: {
        required,
      },
      wageCode: { maxLength: maxLength(20) },
      maxDcPer: {
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      maxDcAmount: {},
      useInBp: {},
      useInGs: {},
      branchId: {
        required,
      },
      workDuration: {},
    },
  },
  created() {
    this.getLocalData();
    this.getDataUpdate();
  },
  methods: {
    dis(option) {
      if (option.id === "FRE") {
        this.isDisabled = true;
        this.isDisable = true;
      } else if (option.id === "PX") {
        this.isDisabled = true;
      } else if (option.id === "HR" || option == null) {
        this.isDisabled = false;
      }
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item);
      });
      this.localDataBranch = localDataBranch;

      // console.log(this.localDataBranch);
    },
    // eslint-disable-next-line no-unused-vars
    formWage() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.updateWage();
      }
    },

    getDataUpdate: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/wage/show", {
          params: {
            wageId: this.wageId,
          },
        })
        .then((response) => {
          this.rowWage = response.data.data;
          this.form.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.form.nameTh = response.data.data.nameTh;
          this.form.nameEn = response.data.data.nameEn;
          this.form.price = response.data.data.price;
          this.form.wageType = {
            wageType: response.data.data,
            name: response.data.data.wageType,
          };
          this.form.wageCode = response.data.data.wageCode;
          this.form.maxDcPer = response.data.data.maxDcPer;
          this.form.maxDcAmount = response.data.data.maxDcAmount;
          this.form.useInBp = response.data.data.useInBp;
          this.form.useInGs = response.data.data.useInGs;
          this.form.workDuration = response.data.data.workDuration;
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "main-user" });
        });
    },

    updateWage: function () {
      // this.overlayFlag = true;

      useNetw
        .put("api/wage/update", {
          branchId: this.form.branchId.branchId,
          wageId: this.wageId,
          wageCode: this.form.wageCode != null ? this.form.wageCode : "",
          nameTh: this.form.nameTh != null ? this.form.nameTh : "",
          nameEn: this.form.nameEn != null ? this.form.nameEn : "",
          useInGs: this.form.useInGs != null ? this.form.useInGs : "0",
          useInBp: this.form.useInBp != null ? this.form.useInBp : "0",
          price: this.form.price != null ? this.form.price : "",
          maxDcPer: this.form.maxDcPer != null ? this.form.maxDcPer : "",
          maxDcAmount:
            this.form.maxDcAmount != null ? this.form.maxDcAmountd : "",
          wageType:
            this.form.wageType.name != null ? this.form.wageType.name : "",
          workDuration:
            this.form.workDuration != null ? this.form.workDuration : "",
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "branch-wages" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
